import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import { BaseResponse } from "../../../interface/base-response";

@Injectable({
  providedIn: 'root'
})
export class PostRequestService {

  constructor(private http: HttpClient) {
  }

  create(body: any, uri: any): Observable<BaseResponse<any>> {
    return this.http
      .post<any>(`${uri}`, body)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    // TODO use ErrorFactory
    return throwError(`An error occurred - Error code ${error.status}`);
  }
}
