import {Injectable} from '@angular/core';
import {PostRequestService} from '../../../service/base/post-reqeust.service';
// @ts-ignore
import {Consult} from '../../pages/consult/consult.component';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConsultService {
  private readonly apiUrl = `${environment.appUrl}/orders`;

  constructor(
    private postRequestService: PostRequestService
  ) {
  }

  save$ = (consult: Consult) => this.postRequestService.create(consult, this.apiUrl);

}
