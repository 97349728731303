import {Injectable} from '@angular/core';
// import {ComponentType} from '@angular/cdk/overlay';

// import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
      // private dialog: MatDialog
  ) {

  }

  // add map with width and height
  openDialog<T>(component: any) {
    // const dialogConfig = new MatDialogConfig();
    //
    // dialogConfig.data = {
    //   data: {}
    // };
    //
    // dialogConfig.maxWidth = '92vw';
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = true;
    // this.dialog.open(component, dialogConfig);
  }

  openDialogAndPassData<T>(component: any, data: Object) {
    // const dialogConfig = new MatDialogConfig();
    //
    // console.log('>>>>>>>>>>' + data);
    // dialogConfig.data = {
    //   data
    // };
    //
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = true;
    //
    // this.dialog.open(component, dialogConfig);
  }

  // openDialogAndClosePrev<T, D>(component: ComponentType<T>, dialogRef: MatDialogRef<D>) {
  //   const dialogConfig = new MatDialogConfig();
  //
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  //
  //   dialogRef.close();
  //   this.dialog.open(component, dialogConfig);
  // }

  // openDialogPassData<T, D>(component: ComponentType<T>, data: Object, closePrevious: boolean, dialogRef?: MatDialogRef<D>) {
  //   const dialogConfig = new MatDialogConfig();
  //
  //   dialogConfig.data = {
  //     data
  //   };
  //
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  //   if (closePrevious) {
  //     dialogRef.close();
  //   }
  //   this.dialog.open(component, dialogConfig);
  // }
  //
  // openInfoDialogPassData<T, D>(component: ComponentType<T>, data: Object, closePrevious: boolean, dialogRef?: MatDialogRef<D>) {
  //   const dialogConfig = new MatDialogConfig();
  //
  //   dialogConfig.data = {
  //     data
  //   };
  //
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.panelClass = 'info-modal';
  //   if (closePrevious) {
  //     dialogRef.close();
  //   }
  //   this.dialog.open(component, dialogConfig);
  // }
}
