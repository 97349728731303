import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../../service/dialog.service';
import {BehaviorSubject, catchError, map, Observable, of} from 'rxjs';
import {DataStateEnum} from '../../../enum/data-state.enum';
import {InfoModalComponent} from '../info-modal/info-modal.component';
import {COUNTRIES_CODE, PHONE_NUMBERS} from '../../../constants/countries.code.constants';
import {ConsultService} from './consult.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { SharedModule } from "../../../shared/shared.module";

@Component({
    selector: 'app-consult-modal',
    templateUrl: './consult-modal.component.html',
    styleUrls: ['./consult-modal.component.css'],
    standalone: true,
    imports: [SharedModule]
})
export class ConsultModalComponent implements OnInit {

    @Input('modal') modal: any;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(InfoModalComponent) infoModalComponent: InfoModalComponent | undefined;
    phoneNumbers = PHONE_NUMBERS;

    appState$: Observable<any> | undefined;
    form: UntypedFormGroup;
    private isLoading = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading.asObservable();
    private successInfo = 'Наш специалист свяжется с вами в скором времени';
    private failureInfo = 'Ошибка, попробуйте еще раз';
    countries = COUNTRIES_CODE;

    constructor(
        private consultService: ConsultService,
        private fb: UntypedFormBuilder,
        private dialogService: DialogService,
        private modalService: NgbModal,
        private ngbActiveModal: NgbActiveModal,
        @Inject(PLATFORM_ID) private platformId: any,
        private route: Router,
        private cdRef: ChangeDetectorRef
    ) {
      this.form = new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(32)]),
            email: new UntypedFormControl('', [Validators.email, Validators.required]),
            message: new UntypedFormControl('', [Validators.minLength(9), Validators.maxLength(500)]),
            privacy: new UntypedFormControl('', [Validators.requiredTrue, Validators.minLength(9), Validators.maxLength(500)])
        });
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.ngbActiveModal.dismiss();
    }

    openPrivacyPage(){
        if (isPlatformBrowser(this.platformId)) {
          const baseUrl = window.location.origin;
          const privacyUrl = baseUrl + '/pages/privacy-information';
          window.open(privacyUrl, '_blank');
        }
    }
    saveConsultOrder(): void {
        this.form.markAllAsTouched();
        this.cdRef.detectChanges();
        this.isLoading.next(true);

        if (this.form.invalid && true) {
            return;
        }
        const formData = this.form.value;
        this.appState$ = this.consultService.save$({
            name: formData.name,
            email: formData.email,
            message: formData.message
        })
            .pipe(
                map(response => {
                    this.form.reset();
                    this.isLoading.next(false);
                    // this.dialogService.openInfoDialogPassData(InfoModalComponent, this.successInfo, true, this.dialogRef);
                    return {dataState: DataStateEnum.LOADED_STATE, appData: response.data};
                }),
                catchError((error: string) => {
                    this.isLoading.next(false);
                    // this.dialogService.openInfoDialogPassData(InfoModalComponent, this.failureInfo, false, this.dialogRef);
                    return of({dataState: DataStateEnum.ERROR_STATE, error});
                }));

        this.appState$.subscribe((result) => {
            this.ngbActiveModal.dismiss(result);
        });
    }

    numericOnly(event: { key: string; }): boolean {
        const patt = /^([0-9])$/;
        return patt.test(event.key);
    }

    protected readonly close = close;
}
