<div id="order-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="text-center">
                    <h3>Получить индивидуальную скидку</h3>
                </div>
                <button class="btn-close " aria-label="Close" (click)="closeDialog()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form" class="needs-validation" id="discountForm" (ngSubmit)="saveConsultOrder()"
                      novalidate>

                    <div class="row">
                        <div class="col-md-6">
                            <label for="name" class="form-label">Имя</label>
                            <div class="input-group has-validation">
                                <ng-container *ngIf="form.controls['name'] as control">
                                    <input type="text" class="form-control" formControlName="name"
                                           id="name"
                                           [ngClass]="{ 'is-invalid': control.touched && control.errors, 'is-valid': control.valid }"
                                           required>
                                    <div *ngIf="control.touched && control.errors && control.hasError('required')"
                                         class="invalid-feedback">
                                        Поле имя обязательно
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="tel" class="form-label">Почта</label>
                            <ng-container *ngIf="form.controls['email'] as control">
                                <input type="text" class="form-control flex-grow-1" id="tel" name="tel"
                                       formControlName="email"
                                       [ngClass]="{ 'is-invalid': control.touched && control.errors, 'is-valid': control.valid }">
                                <div *ngIf="control.touched && control.errors && control.hasError('required')"
                                     class="invalid-feedback">
                                    Поле Почта обязательно
                                </div>
                                <div *ngIf="control.touched && control.errors && control.hasError('email')"
                                     class="invalid-feedback">
                                    Поле Почта написано не правильно
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-md-12">
                            <label for="tel" class="form-label">Сообщение</label>
                            <ng-container *ngIf="form.controls['message'] as control">
                                        <textarea type="text" rows="3" cols="5" class="form-control flex-grow-1"
                                                  name="tel"
                                                  formControlName="message"
                                                  [ngClass]="{ 'is-invalid': control.touched && control.errors, 'is-valid': control.valid }">
                                        </textarea>
                                <div *ngIf="control.touched && control.errors && control.hasError('required')"
                                     class="invalid-feedback">
                                    Поле Сообщение обязательно
                                </div>

                            </ng-container>
                        </div>

                        <div class="col-md-12 mt-2 form-group">

                            <div class="form-check">
                                <ng-container *ngIf="form.controls['privacy'] as control">
                                    <label class="form-check-label" for="invalidCheck2">
                                        Я подтверждаю, что ознакомился и принимаю условия <a  (click)="openPrivacyPage()" class="mk-anchor text-decoration-underline"> политики в отношении
                                        конфиденциальности и обработки персональных данных. </a> </label>
                                    <input formControlName="privacy"
                                           [class.is-invalid]="control.invalid && (control.dirty || control.touched)"
                                           class="form-check-input" type="checkbox" value=""
                                           id="invalidCheck2" required>
                                    <div *ngIf="control.invalid && (control.dirty || control.touched)"
                                         class="text-danger">
                                        Вы должны согласиться с политикой конфиденциальности, чтобы продолжить.
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-footer flex flex-column mt-2">
                <div>
                    <button type="submit" form="discountForm" class="btn btn-solid text-white w-100">
                        Заказать звонок
                    </button>
                </div>

                <div class="text-center contact-block">
                    <p>или позвоните по этому номеру <br><a href="tel:{{phoneNumbers[0]}}">{{phoneNumbers[0]}}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

